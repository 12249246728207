<template>
    <CriarFaq />
</template>

<script>
import CriarFaq from '@/components/Faq/CriarFaq'
export default {
  components: {CriarFaq},
  data(){
    return{}
  }
}
</script>

<style>

</style>